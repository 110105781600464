import React, { useContext, useState } from 'react';
import Header from './header';
import Main from './main';
import Nav from './nav';
import Box from '@mui/material/Box';
import { AppContext } from 'core/context/appContextProvider';

type IProps = {
  children: React.ReactNode;
};

interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
  const [openNav, setOpenNav] = useState<boolean>(false);

  const { loginUser } = useContext(AppContext);

  const toggleOpenNav = () => {
    setOpenNav(!openNav);
  };

  return (
    <>
      <Header
        onOpenNav={toggleOpenNav}
        account={{
          photoURL: loginUser?.image || null,
          displayName: loginUser?.fullName,
          role: loginUser?.px_rank?.name
        }}
      />
      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' }
        }}
      >
        <Nav openNav={openNav} onCloseNav={toggleOpenNav} />
        <Main children={children} />
      </Box>
    </>
  );
};

export default Layout;
