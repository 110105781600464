import React from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import ArticleIcon from '@mui/icons-material/Article';
import ListAltIcon from '@mui/icons-material/ListAlt';

export type NavConfig = {
  title: string;
  path: string;
  icon: React.ReactNode;
  // children?: [{
  //   title: string;
  //   path: string;
  // }]
};

const navConfig = [
  {
    title: 'dashboard',
    path: '/',
    icon: <HomeOutlinedIcon />
  },
  {
    title: 'master',
    icon: <AppsOutlinedIcon />,
    path: '',
    children: [
      {
        title: 'users',
        path: '/users'
        // icon: <PersonOutlineOutlinedIcon />
      },
      {
        title: 'roles',
        path: '/roles'
      },
      {
        title: 'ranks',
        path: '/ranks'
      },
      {
        title: 'subscriptions',
        path: '/subscriptions'
      },
      {
        title: 'rights',
        path: '/rights'
      },
      {
        title: 'modules',
        path: '/modules'
      }
    ]
  },
  {
    title: 'demo',
    path: '/demo',
    icon: <RecordVoiceOverOutlinedIcon />
  },
  // {
  //   title: 'follow up',
  //   path: '/follow-up',
  //   icon: <img src="/images/svg/sidebar_demo.svg" alt="demo" />
  // },
  {
    title: 'orders',
    path: '/orders',
    icon: <ShoppingCartIcon />
  },
  {
    title: 'activity',
    path: '/activity',
    icon: <ListAltIcon/>
  },
  {
    title: 'reports',
    path: '/reports',
    icon: <ArticleIcon />
  }

  // {
  //   title: 'product',
  //   path: '/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
