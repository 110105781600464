import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

const SYSTEM_FONTS = [
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

const PUBLIC_SANS = "PublicSans";
const NOTO_SANS = "NotoSans";
const ROBOTO = "Roboto";

export const palette = {
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
  primary: {
    main: '#014DA1',
  },
  secondary: {
    main: '#E7F2FF',
  },
  info: {
    main: '#608899'
  },
  success: {
    main: '#378d1b'
  },
  error: {
    main: red.A400,
  },
  text: {
    primary: "#014DA1",
    secondary: "#E7F2FF",
    disabled: "#626262",
    hint: "#909090",
    info: "#3C3C4399"
  },
  grey: {
    50: "#FAFAFA",
    100: "#F5F5F5",
    200: "#EAE6E4",
    300: "#E0E0E0", // GRAY 3
    400: "#AFACAA",
    500: "#A6A6A6", // GRAY 1
    600: "#767680",
    700: "#626262", // GRAY 2
    800: "#585858",
    900: "#2E2C2C", // SOFT BLACK
  },
  background: {
    default: '#E7F2FF',
  },
};

export const typography = {
  fontFamily: [PUBLIC_SANS, NOTO_SANS, ROBOTO, ...SYSTEM_FONTS].join(","),
  fontFamilySystem: SYSTEM_FONTS.join(","),
  h1: {
    fontWeight: 800,
    fontSize: createTheme().typography.pxToRem(24),
    color: palette.text.primary,
  },
  h2: {
    fontWeight: 600,
    fontSize: createTheme().typography.pxToRem(20),
    color: palette.text.primary,
  },
  h3: {
    fontWeight: 600,
    fontSize: createTheme().typography.pxToRem(16),
    color: palette.text.primary,
  },
  h4: {
    fontWeight: 600,
    fontSize: createTheme().typography.pxToRem(22), //TODO: We need to change this, h4 should not be bigger than h3, h2 and h1.
    color: palette.text.primary,
  },
  h5: {
    fontWeight: 400,
    fontSize: createTheme().typography.pxToRem(14),
    color: palette.text.primary,
  },
  h6: {
    fontWeight: 500,
    fontSize: createTheme().typography.pxToRem(12),
    color: palette.text.primary,
  },
  subtitle1: {
    fontWeight: 700,
    fontSize: createTheme().typography.pxToRem(14),
    color: palette.text.primary,
  },
  subtitle2: {
    fontWeight: 700,
    fontSize: "14px",
    color: palette.primary.main,
  },
  pageTitle: {
    fontSize: createTheme().typography.pxToRem(34),
    fontWeight: 800,
    lineHeight: 1,
  },
  label: {
    fontSize: createTheme().typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: 1.18,
  },
  formTitle: {
    fontSize: createTheme().typography.pxToRem(24),
    fontWeight: 700,
    lineHeight: 1.16,
  },
  modalTitle: {
    fontSize: createTheme().typography.pxToRem(26),
    fontWeight: 300,
    lineHeight: 1.1,
  },
  modalSubtitle: {
    fontSize: createTheme().typography.pxToRem(16),
    fontWeight: 300,
    lineHeight: 1.22,
  },
  footerNote: {
    fontSize: createTheme().typography.pxToRem(12),
    fontWeight: 500,
    lineHeight: 1.16,
  },
  errorText: {
    fontSize: createTheme().typography.pxToRem(12),
    fontWeight: 400,
    lineHeight: 1.66,
    color: palette.error.main,
  },
  TextBoxTitle: {
    fontSize: createTheme().typography.pxToRem(12),
    fontWeight: 400,
    lineHeight: 1.4375,
    color: palette.text.hint,
  },
  formTitle2:{
    fontSize:createTheme().typography.pxToRem(18),
    fontWeight:700,
  },
  label2:{
    fontSize:createTheme().typography.pxToRem(14),
    fontWeight:600,
    color:palette.text.primary,
  },
  borderBoxText:{
    fontSize:createTheme().typography.pxToRem(16),
    fontWeight:400,
  },
  subInfo: {
    fontSize: createTheme().typography.pxToRem(14),
    fontWeight: 400,
    lineHeight: 1,
    color: palette.text.info,
  },
  cells: {
    fontSize: createTheme().typography.pxToRem(18),
    fontWeight: 400,
    lineHeight: 1.2,
    color: palette.text.primary
  },
  filterUnchecked: {
    fontWeight: 400,
    fontSize: createTheme().typography.pxToRem(16),
    color: palette.text.primary,
  },
  filterChecked: {
    fontWeight: 700,
    fontSize: createTheme().typography.pxToRem(16),
    color: palette.text.primary,
  },
}

// A custom theme for this app
const theme = createTheme({
  palette: palette,
  typography: typography
});

export default theme;
