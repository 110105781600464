import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Alert, styled } from "@mui/material";

export const StyledAlert = styled(Alert)`
  min-width: 480px;
  .MuiAlert-action {
    align-items: center;
  }

  .MuiAlert-message {
    white-space: pre;
    // text-align: center;
    // width: 100%;
  }

  @media (max-width: 540px) {
    min-width: max-content;
    // padding: 10px; 
  }
`;

export const StyledCloseIcon = styled(CloseOutlinedIcon)`
  padding-left: 10px;
  padding-right: 10px;
`;