export const DEBOUNCE_THRESHOLD = 500;
export const PAGE_SIZE = 10;
export const DISPLAY_DATE_FORMAT = 'MM/dd/yyyy';
export const DISPLAY_TIME = 'hh:mm a';
export const API_DATE_FORMAT = 'yyyy-MM-dd';
export const CLIENT_SUBSCRIPTION_EXPIRY = 'isSubscriptionExpire';
export const CLIENT_PROXY_TOKEN_KEY = 'clientProxyToken';
export const TOKEN_KEY = 'clientToken';
export const LOGIN_PAYLOAD_KEY = 'loginPayload';
export const M_DASH_UNICODE = '\u2014';
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const PHONE_REGEX = /^\d{10}$/i;
export const OTP_REGEX = /^\d{6}$/i;
export const DIGIT_REGEX = /^\d*(\.\d{0,2})?$/i; // /^[0-9]+([\.,][0-9]+)?/i;
export const ALPHABETS_REGEX = /^[a-zA-Z\s]+$/;
export const DIGIT__ALPHA_REGEX = /^(?=.*\d|[a-zA-Z]|-)[a-zA-Z\d-]+$/;
export const GST_REGEX =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/i;
export const IFSC_REGEX = /^[A-Z]{4}0[A-Z0-9]{6}$/i;
export const UPI_REGEX = /^[\w.-]+@[\w.-]+$/i; // /^[a-zA-Z0-9.-]{2, 256}@[a-zA-Z][a-zA-Z]{2, 64}$/i;
export const ENTER_KEY_CODE = 13;
export const DEV_API_URL = process.env.REACT_APP_DEV_API_ENDPOINT_URL || '';
export const PROD_API_URL = process.env.REACT_APP_API_ENDPOINT_URL || '';
export const SCOPEROLE = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  BASIC_USER: 'BASIC_USER'
};
export const APIPATH = {
  ACTIVITY: 'activity',
  DASHBOARD: 'dashboard',
  LOGIN: 'auth/login-via-phone',
  VERIFYOTP: 'auth/verify-otp',
  USER: 'user',
  USERMAP: 'usermap',
  ROLE: 'role',
  RANK: 'rank',
  SUBSCRIPTION: 'subscription',
  DEMO: 'demo',
  FOLLOWUP: 'followup',
  ORDER: 'order',
  SETTING: 'setting',
  MODULE: 'module',
  RIGHT: 'right'
};
export const INFO_PANEL_DURATION_MS = 3000;
export type INFO_ALERT_SEVERTIY_TYPES =
  | 'success'
  | 'error'
  | 'info'
  | 'warning';
export type BUTTON_COLOR_OPTIONS = 'primary' | 'secondary' | 'error';
export type BUTTON_STYLE_VARIANTS =
  | 'text'
  | 'contained'
  | 'outlined'
  | undefined;
export type POPOVER_VERTICAL_ORIGIN = 'top' | 'bottom' | 'center';
export type POPOVER_ORIENTATION = 'right' | 'center' | 'left';
export const INFO_PANEL_MAX_WIDTH = '740px';
