import { useContext, useEffect } from 'react';
import { AppContext } from 'core/context/appContextProvider';
import { useNavigate, useLocation } from 'react-router';

type IProps = {
  Component: any;
};

const ProtectedRoute = ({ Component }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { accessModules } = useContext(AppContext);

  let mainPath = pathname;
  if (pathname.includes('/follow-up')) {
    mainPath = '/follow-up';
  }

  useEffect(() => {
    if (accessModules && accessModules.length > 0) {
      const isAccessible: boolean = accessModules
        .map((res) => {
          // Check for View
          if (res.px_module.path === mainPath && res.view) {
            return true;
          } else if (res.px_module.path === `/${mainPath.split('/')[1]}`) {
            // Check for Add
            if (
              (mainPath.includes('add') || mainPath.includes('create')) &&
              res.add
            ) {
              return true;
              // Check for Edit
            } else if (
              (mainPath.includes('edit') || mainPath.includes('update')) &&
              res.edit
            ) {
              return true;
            } else if (mainPath.includes('reports') && res.view) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        })
        .includes(true);
      if (!isAccessible) {
        navigate('/not-authorized', { replace: true });
      }
    }
  }, [accessModules, pathname, mainPath, navigate]);

  return Component;
};

export default ProtectedRoute;
