import React, { lazy, Suspense } from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  Outlet
} from 'react-router-dom';
import Layout from 'layouts';
import { checkIsAuthenticated, getAuthToken } from 'utils/helpers';

import ProtectedRoute from './hooks/ProtectedRoute';
// import { AppContext } from 'core/context/appContextProvider';
import CheckIsSubscriptionExpire from 'components/CheckIsSubscriptionExpire';

export const ActivityPage = lazy(() => import('pages/activity'));
export const AddEditActivityPage = lazy(() => import('pages/activity/components/addEditActivity'));
export const IndexPage = lazy(() => import('pages'));
export const RolePage = lazy(() => import('pages/role'));
export const UserPage = lazy(() => import('pages/user'));
export const RankPage = lazy(() => import('pages/rank'));
export const AddEditUserPage = lazy(
  () => import('pages/user/components/addEditUser')
);
export const AddEditRolePage = lazy(
  () => import('pages/role/components/addEditRole')
);
export const AddEditRankPage = lazy(
  () => import('pages/rank/components/addEditRank')
);

export const SubscriptionPage = lazy(() => import('pages/subscription'));
export const AddEditSubscriptionPage = lazy(
  () => import('pages/subscription/components/addEditSubscription')
);

export const SubscriptionPlanPage = lazy(
  () => import('pages/subscriptionPlan')
);

export const DemoPage = lazy(() => import('pages/demo'));
export const AddEditDemoPage = lazy(
  () => import('pages/demo/components/addEditDemo')
);

export const FollowUpPage = lazy(() => import('pages/followup'));
export const AddEditFollowUpPage = lazy(
  () => import('pages/followup/components/addEditFollowUp')
);

export const OrderPage = lazy(() => import('pages/order'));
// export const AddEditOrderPage = lazy(
//   () => import('pages/order/components/addEditOrder')
// );
export const RightPage = lazy(() => import('pages/right'));

export const ModulesPage = lazy(() => import('pages/modules'));
export const AddEditModulesPage = lazy(
  () => import('pages/modules/components/addEditModules')
);

export const ProfilePage = lazy(() => import('pages/profile'));
export const LoginPage = lazy(() => import('pages/login'));
// export const ProductsPage = lazy(() => import('src/pages/products'));
export const Page404 = lazy(() => import('pages/notFound'));
export const NotAuthorizedPage = lazy(() => import('pages/notAuthorized'));
export const ReportsPage = lazy(() => import('pages/reports'));
export const DemoReport = lazy(() => import('pages/reports/demoReport'));
export const FollowupReport = lazy(
  () => import('pages/reports/followupReport')
);
export const ActivityReport = lazy(() => import('pages/reports/activityReport'));
export const PrivacyPolicy = lazy(() => import('pages/privacyPolicy'));
export const TermsAndCondition = lazy(() => import('pages/termsAndCondition'));

// ----------------------------------------------------------------------

const Routes = () => {
  const routes = createBrowserRouter([
    {
      path: '/',
      element: (
        // !getIsSubscriptionFlag() ? (
        <CheckIsSubscriptionExpire>
          <Layout>
            <Suspense>
              <IndexPage />
            </Suspense>
          </Layout>
        </CheckIsSubscriptionExpire>
      ),
      // ) : (
      //   <Navigate to={'/subscription-plan'} replace={true} />
      // ),
      loader: checkIsAuthenticated
    },
    {
      path: '/profile',
      element: (
        // !getIsSubscriptionFlag() ? (
        <CheckIsSubscriptionExpire>
          <Layout>
            <Suspense>
              <Outlet />
            </Suspense>
          </Layout>
        </CheckIsSubscriptionExpire>
      ),
      // ) : (
      //   <Navigate to={'/subscription-plan'} replace={true} />
      // ),
      loader: checkIsAuthenticated,
      children: [{ element: <ProfilePage />, index: true }]
    },
    {
      path: '/activity',
      element: (
        <CheckIsSubscriptionExpire>
          <Layout>
            <Suspense>
              <Outlet/>
            </Suspense>
          </Layout>
        </CheckIsSubscriptionExpire>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<ActivityPage />} />
        },
        {
          path: '/activity/:mode',
          element: <ProtectedRoute Component={<AddEditActivityPage />} />
        },
        {
          path: '/activity/:mode/:id',
          element: <ProtectedRoute Component={<AddEditActivityPage />} />
        }
      ]
    },
    {
      path: '/users',
      element: (
        // !getIsSubscriptionFlag() ? (
        <CheckIsSubscriptionExpire>
          <Layout>
            <Suspense>
              <Outlet />
            </Suspense>
          </Layout>
        </CheckIsSubscriptionExpire>
      ),
      // ) : (
      //   <Navigate to={'/subscription-plan'} replace={true} />
      // ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<UserPage />} />
        },
        {
          path: '/users/:mode',
          element: <ProtectedRoute Component={<AddEditUserPage />} />
        },
        {
          path: '/users/:mode/:id',
          element: <ProtectedRoute Component={<AddEditUserPage />} />
        }
      ]
    },
    {
      path: '/roles',
      element: (
        // !getIsSubscriptionFlag() ? (
        <CheckIsSubscriptionExpire>
          <Layout>
            <Suspense>
              <Outlet />
            </Suspense>
          </Layout>
        </CheckIsSubscriptionExpire>
      ),
      // ) : (
      //   <Navigate to={'/subscription-plan'} replace={true} />
      // ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<RolePage />} />
        },
        {
          path: '/roles/:mode',
          element: <ProtectedRoute Component={<AddEditRolePage />} />
        },
        {
          path: '/roles/:mode/:id',
          element: <ProtectedRoute Component={<AddEditRolePage />} />
        }
      ]
    },
    {
      path: '/ranks',
      element: (
        // !getIsSubscriptionFlag() ? (
        <CheckIsSubscriptionExpire>
          <Layout>
            <Suspense>
              <Outlet />
            </Suspense>
          </Layout>
        </CheckIsSubscriptionExpire>
      ),
      // ) : (
      //   <Navigate to={'/subscription-plan'} replace={true} />
      // ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<RankPage />} />
        },
        {
          path: '/ranks/:mode',
          element: <ProtectedRoute Component={<AddEditRankPage />} />
        },
        {
          path: '/ranks/:mode/:id',
          element: <ProtectedRoute Component={<AddEditRankPage />} />
        }
      ]
    },
    {
      path: '/subscriptions',
      element: (
        // !getIsSubscriptionFlag() ? (
        <CheckIsSubscriptionExpire>
          <Layout>
            <Suspense>
              <Outlet />
            </Suspense>
          </Layout>
        </CheckIsSubscriptionExpire>
      ),
      // ) : (
      //   <Navigate to={'/subscription-plan'} replace={true} />
      // ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<SubscriptionPage />} />
        },
        {
          path: '/subscriptions/:mode',
          element: <ProtectedRoute Component={<AddEditSubscriptionPage />} />
        },
        {
          path: '/subscriptions/:mode/:id',
          element: <ProtectedRoute Component={<AddEditSubscriptionPage />} />
        }
      ]
    },
    {
      path: '/subscription-plan',
      element: (
        // getIsSubscriptionFlag() ? (
        <Suspense>
          <Outlet />
        </Suspense>
      ),
      // ) : (
      //   <Navigate to={'/'} replace={true} />
      // ),
      loader: checkIsAuthenticated,
      children: [{ element: <SubscriptionPlanPage />, index: true }]
    },
    {
      path: '/demo',
      element: (
        <CheckIsSubscriptionExpire>
          <Layout>
            <Suspense>
              <Outlet />
            </Suspense>
          </Layout>
        </CheckIsSubscriptionExpire>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<DemoPage />} />
        },
        {
          path: '/demo/:mode',
          element: <ProtectedRoute Component={<AddEditDemoPage />} />
        },
        {
          path: '/demo/:mode/:id',
          element: <ProtectedRoute Component={<AddEditDemoPage />} />
        }
      ]
    },
    {
      path: '/follow-up/:demoID',
      element: (
        <CheckIsSubscriptionExpire>
          <Layout>
            <Suspense>
              <Outlet />
            </Suspense>
          </Layout>
        </CheckIsSubscriptionExpire>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<FollowUpPage />} />
        },
        {
          path: '/follow-up/:demoID/:mode',
          element: <ProtectedRoute Component={<AddEditFollowUpPage />} />
        },
        {
          path: '/follow-up/:demoID/:mode/:id',
          element: <ProtectedRoute Component={<AddEditFollowUpPage />} />
        }
      ]
    },
    {
      path: '/orders',
      element: (
        <CheckIsSubscriptionExpire>
          <Layout>
            <Suspense>
              <Outlet />
            </Suspense>
          </Layout>
        </CheckIsSubscriptionExpire>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<OrderPage />} />
        },
        {
          path: '/orders/:mode',
          element: <ProtectedRoute Component={<AddEditFollowUpPage />} />
        },
        {
          path: '/orders/:mode/:id',
          element: <ProtectedRoute Component={<AddEditFollowUpPage />} />
        }
      ]
    },
    {
      path: '/rights',
      element: (
        <CheckIsSubscriptionExpire>
          <Layout>
            <Suspense>
              <Outlet />
            </Suspense>
          </Layout>
        </CheckIsSubscriptionExpire>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<RightPage />} />
        }
      ]
    },
    {
      path: '/modules',
      element: (
        <CheckIsSubscriptionExpire>
          <Layout>
            <Suspense>
              <Outlet />
            </Suspense>
          </Layout>
        </CheckIsSubscriptionExpire>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<ModulesPage />} />
        },
        {
          path: '/modules/:mode',
          element: <ProtectedRoute Component={<AddEditModulesPage />} />
        },
        {
          path: '/modules/:mode/:id',
          element: <ProtectedRoute Component={<AddEditModulesPage />} />
        }
      ]
    },
    {
      path: '/reports',
      element: (
        <CheckIsSubscriptionExpire>
          <Layout>
            <Suspense>
              <Outlet />
            </Suspense>
          </Layout>
        </CheckIsSubscriptionExpire>
      ),
      loader: checkIsAuthenticated,
      children: [
        {
          index: true,
          element: <ProtectedRoute Component={<ReportsPage />} />
        },
        {
          path: '/reports/demo',
          element: <ProtectedRoute Component={<DemoReport />} />
        },
        {
          path: '/reports/followup',
          element: <ProtectedRoute Component={<FollowupReport />} />
        },
        {
          path: '/reports/activty',
          element: <ProtectedRoute Component={<ActivityReport />} />
        }
      ]
    },
    {
      path: 'privacy-policy',
      element: (
        <Suspense>
          <PrivacyPolicy/>
        </Suspense>
      )
    },
    {
      path: 'terms-and-condition',
      element: (
        <Suspense>
          <TermsAndCondition/>
        </Suspense>
      )
    },
    {
      path: 'login',
      element: !getAuthToken() ? (
        <Suspense>
          <LoginPage />
        </Suspense>
      ) : (
        <Navigate to={'/'} replace={true} />
      )
    },
    {
      path: '404',
      element: <Page404 />
    },
    {
      path: 'not-authorized',
      element: <NotAuthorizedPage />
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />
    }
  ]);

  return <RouterProvider router={routes} />;
};

export default Routes;
