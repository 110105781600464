import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";

import Routes from "routes";

import { AppProvider } from "core/context/appContextProvider";

import FallbackScreen from "components/Fallback/FallbackScreen";

export default function App() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: any) => {
      event.preventDefault();
      setDeferredPrompt(event);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      // deferredPrompt.prompt();
      // deferredPrompt.userChoice.then((choiceResult) => {
      //   if (choiceResult.outcome === 'accepted') {
      //     console.log('User accepted the install prompt');
      //   } else {
      //     console.log('User dismissed the install prompt');
      //   }
      //   setDeferredPrompt(null);
      // });
    }
  };
  return (
    <AppProvider>
      {/* <div>
        <h1>Your React PWA</h1>
        <button onClick={handleInstallClick}>Install App</button>
      </div> */}
      <ErrorBoundary FallbackComponent={FallbackScreen}>
        <Routes />
      </ErrorBoundary>
    </AppProvider>
  );
}
