import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getIsSubscriptionFlag } from 'utils/helpers';

const CheckIsSubscriptionExpire = ({ children }: any) => {
  const navigate = useNavigate();
  const isSubscriptionExpired = getIsSubscriptionFlag();

  useEffect(() => {
    if (isSubscriptionExpired) {
      return navigate('/subscription-plan');
    }
  }, [isSubscriptionExpired, navigate]);

  return children;
};

export default CheckIsSubscriptionExpire;
